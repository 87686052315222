/* CSS for Viewer Component */

/* Page */
html, body{
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
}

/* Viewer container */
.viewer-container{
    background-position: center center, center center;
    background-repeat: no-repeat, no-repeat;
    background-size: auto, cover;
}

/* Model state button */
.button-model-state {
    position: absolute !important;
    top: 10px !important;
    right: 10px !important;
    z-index: 9;
    background: #fff;
    color: #000;
    border: 0px;
    border-radius: 0;
    outline: none;
    font-size: 16px;
    cursor: pointer;
    background-image: url(../assets/clickIcon.png);
    height: 50px;
    width: 50px;
    background-color: transparent;
    background-size: contain;
    background-repeat: no-repeat;
}

.button-model-state.bottom {
    background-image: url(../assets/clickIconBottom.png);
}

/* Model state button content */
.button-model-state:after {
    content: 'Bonus';
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: -16px;
    font-size: 10px;
}

.button-model-state.bottom:after {
    content: 'Straighten';
}

/* Canvas for the base */
#drawing-canvas {
    position: absolute;
    top:0;
    left: 0;
    z-index: 0;
}
